import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSurveys(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSurvey(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/survey`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSurvey(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSurveyByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/survey/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/survey-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveySurveyor(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-surveyor`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveyWard(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-ward`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveyIncomplete(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-incomplete`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveyDuplicate(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-duplicate`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveyDeleted(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-deleted`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSurveyChanged(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/survey-changed`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
