import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getReports(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/report/create', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
