import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getAttachments(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/attachment`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createAttachment(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/attachment/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeAttachment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/attachment`, data)
                    .then(response => {
                        console.log(response);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showAttachment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/attachment/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editAttachment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/attachment/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateAttachment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/attachment/${data.id}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteAttachment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/attachment/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
