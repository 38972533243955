const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Security.vue');

const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleForm.vue');
// const UserView = () => import (/* webpackChunkName: "program-group" */'./views/Program/ProgramView.vue');
const FactSheet = () => import (/* webpackChunkName: "survey-group" */ './views/FactSheet/FactSheet.vue');
const AttachmentIndex = () => import (/* webpackChunkName: "attachment-group" */ './views/Attachment/AttachmentIndex.vue');
const AttachmentForm = () => import (/* webpackChunkName: "attachment-group" */ './views/Attachment/AttachmentForm.vue');

const SurveyForm = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyForm.vue');
const SurveyIndex = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyIndex.vue');
const SurveySurveyor = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveySurveyor.vue');
const SurveyWard = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyWard.vue');
const SurveyIncomplete = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyIncomplete.vue');
const SurveyDuplicate = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyDuplicate.vue');
const SurveyDeleted = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyDeleted.vue');
const SurveyChanged = () => import (/* webpackChunkName: "survey-group" */ './views/Survey/SurveyChanged.vue');

const ReportIndex = () => import (/* webpackChunkName: "report-group" */ './views/Report/ReportIndex.vue');

const LabelIndex = () => import (/* webpackChunkName: "label-group" */ './views/Labels/LabelIndex.vue');
const LabelForm = () => import (/* webpackChunkName: "label-group" */ './views/Labels/LabelForm.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/security',
        name: 'security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
        }
    },

    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'role-edit',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports',
        name: 'report-index',
        component: ReportIndex,
        meta: {
            requiresAuth: true,
        }
    },

    //Label Management
    {
        path: '/label-management',
        name: 'label-management-index',
        component: LabelIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/label-management/create',
        name: 'label-management-create',
        component: LabelForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/label-management/:id/edit',
        name: 'label-management-edit',
        params: true,
        component: LabelForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Survey Report Management
    {
        path: '/survey',
        name: 'survey-index',
        params: true,
        component: SurveyIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-surveyor',
        name: 'survey-surveyor',
        params: true,
        component: SurveySurveyor,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-ward',
        name: 'survey-ward',
        params: true,
        component: SurveyWard,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-incomplete',
        name: 'survey-incomplete',
        params: true,
        component: SurveyIncomplete,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-duplicate',
        name: 'survey-duplicate',
        params: true,
        component: SurveyDuplicate,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-deleted',
        name: 'survey-deleted',
        params: true,
        component: SurveyDeleted,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/survey-changed',
        name: 'survey-changed',
        params: true,
        component: SurveyChanged,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/facts-sheet',
        name: 'fact-sheet',
        params: true,
        component: FactSheet,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments',
        name: 'attachment-index',
        params: true,
        component: AttachmentIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments/create',
        name: 'attachment-create',
        params: true,
        component: AttachmentForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments/:id/edit',
        name: 'attachment-edit',
        params: true,
        component: AttachmentForm,
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes
