import axios from "axios";
import i18n from "../../i18n";

const state = {
    locale: i18n.locale,
    logo: '',
    user: {},
    showFullLoader: false,
    currentMonth: null,
    currentFiscalYear: null,
    client_id: null,
    navigations: [
        {
            icon: "bsx-dashboard",
            id: 0,
            label: "menuitems.dashboard.text",
            name: "dashboard",
            navigation: "/dashboard"
        }
    ],
    months: ['वैशाख', 'जेठ', 'असार', 'साउन', 'भदौ', 'असोज', 'कार्तिक', 'मंसिर', 'पुष', 'माघ', 'फागुन', 'चैत'],
}
const mutations = {
    locale(state, value) {
        state.locale = value
    },
    user(state, value) {
        state.user = value
    },
    showFullLoader(state, value) {
        state.showFullLoader = value
    },
    currentMonth(state, value) {
        state.currentMonth = value
    },
    currentFiscalYear(state, value) {
        state.currentFiscalYear = value
    },
    client_id(state, value) {
        state.client_id = value
    },
    logo(state, value) {
        state.logo = value
    },
    months(state, value) {
        state.months = value
    },
    navigations(state, value) {
        state.navigations.push(value)
    },
}
const getters = {
    locale: state => state.locale,
    user: state => state.user,
    showFullLoader: state => state.showFullLoader,
    currentMonth: state => state.currentMonth,
    currentFiscalYear: state => state.currentFiscalYear,
    client_id: state => state.client_id,
    logo: state => state.logo,
    months: state => state.months,
    navigations: state => state.navigations,
}
const actions = {
    getNavigations(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/navigation`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/${data.username}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSecurity(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/security/${data.username}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getFactSheetReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/facts-sheet`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
