import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getLabelManagement(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/label-management`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createLabelManagement(context, params) {
        console.log(params)
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/label-management/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeLabelManagement(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/label-management`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showLabelManagementById(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/label-management/${data.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editLabelManagement(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/label-management/${params.id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLabelManagement(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/label-management/${data.id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteLabelManagement(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/label-management/${data.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
